<template>
    <FormWrapper
      :form="form"
      :hideForm="hideForm"
      :handleOpened="() => handleProps({ key: 'opened', value: !form.opened })"
    >
        <span class="exam-type">Tipo de exame: {{ form.fields.isUsingGlassesOrLensesDuringTheExam === null ? 'Não informado' : (form.fields.isUsingGlassesOrLensesDuringTheExam ? 'AVPPCC' : 'AVPPSC') }}</span>
        <b-row>
        <b-col>
          <b-row>
            <b-col>
              <div class="custom-input-group" >
                <div class="custom-input">
                    <div class="eye-area" >
                      <EyeFill /> D
                    </div>
                    <div class="custom-input eye-box-container">
                      <div
                        class="text-area type-2 with-br"
                      >
                        <div >
                          <input
                            autocomplete="off"
                            type="text"
                            class="form-control"
                            :value="form.fields.bvOlhoDireito"
                            :readonly="true"
                            :disabled="true"
                          >
                        </div>
                      </div>
                    </div>
                </div>
                <div class="custom-input">
                    <div class="eye-area">
                    <EyeFill /> E
                    </div>
                  <div class="custom-input eye-box-container">
                    <div
                      class="text-area type-2 with-br"
                    >
                      <div>
                        <input
                          autocomplete="off"
                          type="text"
                          class="form-control"
                          :value="form.fields.bvOlhoEsquerdo"
                          :readonly="true"
                          :disabled="!true"
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <div class="custom-input no-bb">
                    <div class="eye-area">
                    <EyeFill /> <EyeFill />
                    </div>
                  <div class="custom-input eye-box-container">
                    <div
                      class="text-area type-2 with-br"
                    >
                      <div>
                        <input
                          autocomplete="off"
                          type="text"
                          class="form-control"
                          :value="form.fields.bvAmbosOsOlhos"
                          :readonly="true"
                          :disabled="true"
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </FormWrapper>
</template>

<script>
  import { mapState, mapActions, mapGetters } from 'vuex'
  import attendanceDataMixin from '@/mixins/Attendance/attendanceDataMixin'
  
  export default {
    components: {
      FormWrapper: () => import('@/components/Attendance/Forms/FormWrapper'),
      EyeFill: () => import('@/assets/icons/eye-fill.svg'),
    },
    computed: {
      ...mapState({
        canEdit: state => state.attendance.canEdit,
        form: state => state.attendance.form.bvTesteAcuidadeVisualParaPerto,
        medicalRecordsStore: state => state.attendance.medicalRecordsStore,
        attendance: state => state.attendance.attendance,
      }),
      ...mapGetters('attendance', ['bvConsultation', 'cidConsultation']),
    },
    mixins: [attendanceDataMixin('medicalRecordsStore')],
    data() {
      return {
        loading: false,
        data: null
      }
    },
    methods: {
      ...mapActions('attendance/form', ['handleActiveModule', 'updateMedicalRecord', 'destroyMedicalRecord']),
      ...mapActions('attendance/form/bvTesteAcuidadeVisualParaPerto', ['handleFields', 'handleProps']),
      updateForm(key, value) {
        this.handleFields({ key, value })
      },
      hideForm(){
        this.handleProps({ key: 'showing', value: false })
        this.destroyMedicalRecord(this.form)
        Object.keys(this.form.fields).forEach(key => {
          this.form.fields[key] = null
        });
      },
      async getBvAcuidade() {
        if (!this.bvConsultation?.nearVisualAcuity) return;
        const nearVisualAcuity = this.bvConsultation?.nearVisualAcuity
        this.form.fields.bvOlhoDireito = nearVisualAcuity?.response?.right?.classification || 'Não realizado'
        this.form.fields.bvOlhoEsquerdo = nearVisualAcuity?.response?.left?.classification || 'Não realizado'
        this.form.fields.bvAmbosOsOlhos = nearVisualAcuity?.response?.both?.classification || 'Não realizado'
        this.form.fields.isUsingGlassesOrLensesDuringTheExam = nearVisualAcuity?.response?.isUsingGlassesOrLensesDuringTheExam
        this.form.fields.createdAt = nearVisualAcuity?.createdAt
        this.updateMedicalRecord(this.form)
      },
    },
    watch: {
      'bvConsultation': function (value) {
        if(value){
          this.getBvAcuidade()
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
.custom-input-group {
    display: flex;
    flex-direction: column;
    border: 1px solid var(--neutral-300);
    border-radius: 8px;
    margin: 24px 0 16px 0;

    .custom-input {
      flex: 1;
      height: 38px;
      border: 8px;
      display: flex;
      flex-direction: row;
      border-bottom: 1px solid var(--neutral-300);
      justify-content: center;

      .eye-area {
        width: 60px;
        background-color: var(--neutral-100);
        border-right: 1px solid var(--neutral-300);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px 0 0 0;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;

        svg {
          width: 16px;
          height: 16px;
          fill: var(--type-active);
          margin-right: 4px;
        }
      }

      &.no-bb {
        border-bottom: 0;

        .eye-area {
          border-radius: 0 0 0 8px;
        }
      }

      .text-area {
        flex: 1;
        line-height: 55px;
        margin-left: 16px;
        font-weight: 400;
        font-size: 16px;
        color: var(--type-active);

        .form-control {
          border: 0 !important;
          border-radius: 0 !important;

          &.with-bbr {
            border-radius: 0 0 8px 0 !important;
          }

          &.with-btr {
            border-radius: 0 8px 0 0 !important;
          }
        }

        &.type-2 {
          position: relative;
          margin-left: 0;
          text-align: center;

          span {
            width: 100%;
            position: absolute;
            top: -19px;
            left: 0;
            font-weight: 700;
            font-size: 12px;
            line-height: 16px;
            color: var(--type-active);
          }
        }
        &.with-br {
          border-right: 1px solid var(--neutral-300) !important;
        }

        &.with-brtr {
          border-radius: 0 8px 0 0 !important;
        }

        &.with-brbr {
          border-radius: 0 0 8px 0 !important;
        }
      }
    }
  }

  .eyeValueChange {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin: 0 !important;
    position: relative;
    margin-left: -30px !important;
    z-index: 5;
    & > div {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      width: 30px;

      button {
        height: 20px;
        width: 30px;
        color: var(--greys-60);
        background-color: #fff;
        position: relative !important;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border: 1.5px solid var(--blue-100) !important;

        p {
          font-weight: 700;
        }

      }
    }
  }

  .eye-box-container {
    .eyeValueChange {
      visibility: hidden;
    }

    &:hover {
      .eyeValueChange {
        visibility: visible;
      }
    }
  }

  .exam-type {
    font-size: 0.9rem;
  }
</style>